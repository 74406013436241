import axios from "@/libs/request";

const api = {
    login: "/user/wechatLogin",
    postMessage: "/user/postMessage",
    payPostMessage: "/user/payPostMessage",
    getDetail: "/user/getDetail",
    myMessage: "/user/myMessage",
    deleteMessage: "/user/deleteMessage",
    hidePhone: "/user/hidePhone",
    payOrder: "/user/payOrder",
    buyNow: "/user/buyNow",
    myOrder: "/user/myOrder",
    userInfo: "/user/userInfo"
};

const post = "post";
const get = "get";

export function wxLogin(data) {
    return axios.request({
        url: api.login, method: "post", data
    });
}

export function postMessage(data) {
    return axios.request({
        url: api.postMessage, method: "post", data
    });
}

export function payPostMessage(data) {
    return axios.request({
        url: api.payPostMessage, method: "post", data
    });
}

export function getDetail(params) {
    return axios.request({
        url: api.getDetail, method: "get", params
    });
}

export function myMessage(params) {
    return axios.request({
        url: api.myMessage, method: "get", params
    });
}

export function deleteMessage(params) {
    return axios.request({
        url: api.deleteMessage, method: "get", params
    });
}

export function hidePhone(data) {
    return axios.request({
        url: api.hidePhone, method: "post", data
    });
}

export function postBuyNow(data) {
    return axios.request({
        url: api.buyNow, method: "POST", data
    });
}

export function getMyOrder(params) {
    return axios.request({
        url: api.myOrder, method: "get", params
    });
}

export function getUserInfo() {
    return axios.request({
        url: api.userInfo, method: "GET"
    });
}

export function payOrder(params) {
    return axios.request({
        url: api.payOrder, method: "get", params
    });
}
