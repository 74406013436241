const home = () => import("views/home");
const issue = () => import("views/issue");
const myOrder = () => import("views/my-order");
const detail = () => import("views/detail");
const buyNow = () => import("views/buy-now");
const contact = () => import("views/contact-admin");
const orderDetail = () => import("views/order-detail");
const my = () => import("views/my");
const test = () => import("views/issue-test");

export default [{
    path: "/", redirect: "/home"
}, {
    path: "/home", name: "home", component: home, meta: {title: "商品列表"}
}, {
    path: "/issue", name: "issue", component: issue, meta: {title: "信息发布"}
}, {
    path: "/my-order", name: "myOrder", component: myOrder, meta: {title: "我的订单"}
}, {
    path: "/detail", name: "detail", component: detail, meta: {title: "商品详情"}
}, {
    path: "/buy-now", name: "buyNow", component: buyNow, meta: {title: "立即购买"}
}, {
    path: "/contact", name: "contact", component: contact, meta: {title: "联系站长"}
}, {
    path: "/issue-test", name: "issueTest", component: test, meta: {title: "信息发布"}
}, {
    path: "/order-detail", name: "orderDetail", component: orderDetail, meta: {title: "订单详情"}
}, {
    path: "/my", name: "my", component: my, meta: {title: "我的"}
}];
