import { createApp } from 'vue'
import App from './App.vue'
import './mock'
import vant from 'vant'
import "vant/lib/index.css"
import router from "./router"
import '@/libs/rem'
import wx from "weixin-js-sdk"

const app = createApp(App)
app.config.globalProperties.$wx = wx

app.use(vant)
app.use(router)

app.mount('#app')