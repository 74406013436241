<template>
  <div id="app">
    <div class="top-bar" v-if="show_navbar">
      <van-nav-bar
          :title="title"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
      />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {reactive, toRefs} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "App",
  setup(el) {
    const state = reactive({
      show_navbar: false,
      title: ""
    });
    const router = useRouter();
    const onClickLeft = function () {
      router.go(-1);
    };

    //路由守卫
    router.beforeEach((to, from, next) => {
      document.title = to.meta.title ? to.meta.title : "永润公众号";
      // to.matched 是一个数组（匹配到是路由记录）
      // to.matched.some(record => record.meta.requiresAuth)
      if (to.name == "home" || to.name == "my") {
        // state.title = to.meta.title;
        state.show_navbar = false;
      } else {
        state.show_navbar = true;
      }
      if (to.meta.requiresAuth) {
        if (!store.state.user) {
          // 跳转到登录页面
          next({
            name: "login",
            query: {
              // 通过 url 传递查询字符串参数
              redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
            }
          });
        } else {
          next(); // 允许通过
        }
      } else {
        next(); // 允许通过
      }
    });

    return {
      ...toRefs(state),
      onClickLeft
    };
  }
};
</script>

<style lang="less">
@color-green: #fd6f7d;

:root {
  --van-nav-bar-title-text-color: @color-green;
  --van-nav-bar-icon-color: @color-green;
  --van-nav-bar-text-color: @color-green;

  --color-green: @color-green;
}

.top-bar {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 10;
  color: #fd6f7d;
}

body {
  margin: 0px !important;
  height: 100%;
  font-family: microsoft yahei, "宋体", verdana, arial, helvetica, sans-serif !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}

.padding-20 {
  padding: 20px;
  box-sizing: border-box;
}

.padding-5 {
  padding: 5px;
  box-sizing: border-box;
}

.padding-10 {
  padding: 20px;
  box-sizing: border-box;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.line {
  height: 1px;
  background: #efeff4;
  margin: 10px 0px;
  width: 100%;
}

.background-f7f8fa {
  background-color: #f7f8fa;
}

.margin-top-20 {
  margin-top: 20px;
}

.content-padding-top {
  padding-top: 46px;
  box-sizing: border-box;
}

.van-dialog__header {
  font-size: 20px !important;
}

.ml10 {
  margin-left: 10px;
}

.mt10 {
  margin-top: 10px;
}
</style>
