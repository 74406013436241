import Cookies from "js-cookie";
import {url} from "@/config/index";
import {token_test} from "@/config";

export const TOKEN_KEY = "token";


export const setToken = (token, day) => {
    Cookies.set(TOKEN_KEY, token, {expires: day || 30});
};

export const setCookies = (key, data) => {
   return Cookies.set(key, data, {expires: 30});
};

export const getCookies = (key) => {
    const data = Cookies.get(key);
    return data;
};

export const getToken = () => {
    const token = token_test ? token_test : Cookies.get(TOKEN_KEY);
    if (token) return token; else return false;
};

// 设置存储
export const setCookie = (key, data, day) => {
    Cookies.set(key, data, {expires: day || 1});
};

export const getCookie = (key) => {
    const token = Cookies.get(key);
    if (token) return token; else return false;
};


export const imageSrc = (html) => {
    const result = html.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
        if (capture.indexOf("http") >= 0) {
            return `<img src=${capture} style="width:100%">`;
        } else {
            return `<img src=${url + capture} style="width:100%">`;
        }
    });
    return result;
};

export function getUrlParam(name) {
    //构造一个含有目标参数的正则表达式对象
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    //匹配目标参数
    var r = window.location.search.substr(1).match(reg);
    //返回参数值
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
}
