/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios'
import { Toast, Form } from 'vant'
// import Router from '@/router/index'
// import store from '@/store'

import { getToken, setToken } from '@/libs/util'
import { url, redirect_uri, appid } from '@/config'

class HttpRequest {
  constructor() {
    this.baseURL = url
    this.queue = {}
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseURL,
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Authorization': getToken()
      }
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)

      if (res.data.code === 401) {
        setToken('')
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      }

      return res.data
    }, error => {
      this.destroy(url)

      const res = error.response

      if (res.data.code === 403) {
        Toast.fail('访问受限');
      } else if (res.data.code === 401) {
        setToken('')

        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`

        Toast.fail('授权过期，重新登录');
      } else if (res.status >= 400) {
        setToken('')
      }


      return Promise.reject(error)
    })
  }
  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}

export default HttpRequest