import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import route from "./routes";
import {getToken, setToken, getUrlParam} from "@/libs/util";
import {redirect_uri, appid, token_test} from "@/config";
import {wxLogin} from "@/api/user";

const router = createRouter({
    // history: createWebHashHistory(), //hash路由
    history: createWebHistory(), //history路由
    mode: "history", routes: route
});

//路由
router.beforeEach((to, from, next) => {
    const ivtCode = to.query.ivtCode;

    const code = getUrlParam("code");

    if (ivtCode) {
        Vue.ls.set("ivt_code", ivtCode);
    }

    if (token_test) {
        setToken(token_test);
    }

    const token = getToken();

    if (!token) { //未登录
        if (code) {
            wxLogin({
                code: code
            }).then(res => {
                console.log("------------res--------", res);
                if (res.status == 200) {
                    setToken(res.data.token, 30);
                    next();
                } else {
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                }
            });
        } else if (to.name == "home" || to.name == "detail") {
            next();
        } else {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        }

    } else {
        next();
    }
});

export default router;
